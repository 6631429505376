@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  overflow: overlay;
  font-family: "Poppins", sans-serif;
}
* {
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
}
*::-webkit-scrollbar {
  width: 0.5em;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200);
  border-radius: 10px;
}
